import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import Dosing2Column from '../components/Dosing2Column';
import dosingImg from '../assets/dosing-graphics/lenvima-dose-reduction-mobile.png';
import dosingImgDesktop from '../assets/dosing-graphics/lenvima-dose-reduction-desktop.png';
import fullWidthImg from '../assets/dosing-graphics/keytruda-dosing-mobile.png';
import fullWidthImgDesktop from '../assets/dosing-graphics/keytruda-dosing-desktop.png';
import { Body, H4, Ul, H3, DesktopOnly, MobileOnly } from '../util/typography';
import TextImageFullWidth from '../components/TextImageFullWidth';
import PageIntroduction from '../components/PageIntroduction';
import RelatedContent from '../components/RelatedContent';
import styled from 'styled-components';
import { respondTo } from '../util/breakpoints';
import { jobCodes } from '../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) | Dosage and Administration`,
    keywords: `keytruda and lenvima dosage`,
    description: `Health care professionals can review dosage and administration information for KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/dosing/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/dosing/","@type":"Drug","proprietaryName":"KEYTRUDA®","nonProprietaryName":"pembrolizumab","dosageForm":"injection","administrationRoute":"infusion","prescribingInfo":"https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_pi.pdf","doseSchedule":{"@type":"DoseSchedule","doseUnit":"mg","doseValue":"200 or 400","frequency":"Every 3 Weeks or Every 6 Weeks","targetPopulation":"Adults"}}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/dosing/","@type":"Drug","proprietaryName":"LENVIMA®","nonProprietaryName":"lenvatinib","dosageForm":"capsule","administrationRoute":"orally","prescribingInfo":"https://www.lenvima.com/-/media/Project/EISAI/Lenvima/PDF/prescribing-information.pdf","doseSchedule":{"@type":"DoseSchedule","doseUnit":"mg","doseValue":"20","frequency":"once daily at the same time each day","targetPopulation":"Adults"}}`
    ]
}

const jobCode = jobCodes[0].jobCode;

const RCLinks = [
    {
        eyebrow: 'Safety',
        title: 'Monitor and help manage adverse reactions',
        to: '/safety'
    },
    {
        eyebrow: 'Resources',
        title: 'Download resources for HCPs, including Dosage and Administration Guide',
        to: '/professional-resources'
    },
];

const BreakForSmallerDevices = styled.span`
    text-wrap: wrap;
    white-space: wrap;
    @media (min-width: 360px) {
        text-wrap: nowrap;
        white-space: nowrap;
    }
`;

const StyledRC = styled(RelatedContent)`
    margin-bottom: 20px;
    ${respondTo.md`
        margin-bottom: 30px;
    `}
`;

const Dosing = ({location}) => {
    return (
        <DefaultLayout location={ location } name='Dosing and Administration' pageMeta={pageMeta} jobCode={jobCode}>
            <PageIntroduction>
                <H3>Understanding dosing and administration for <keytitle><BreakForSmallerDevices>KEYTRUDA®&nbsp;(pembrolizumab)</BreakForSmallerDevices></keytitle> <b>+</b>  <lentitle>LENVIMA®&nbsp;(lenvatinib)</lentitle></H3>
                <Body><b>When administering KEYTRUDA in combination with LENVIMA, modify the dosage of one or both drugs as appropriate. Withhold or discontinue KEYTRUDA or withhold, dose reduce, or discontinue LENVIMA as shown in this resource.</b></Body>
            </PageIntroduction>
            <TextImageFullWidth imgSrc={fullWidthImg} imgAlt='KEYTRUDA® (pembrolizumab) Can Be Administered After Dilution Every 3 Weeks or Every 6 Weeks as a 30-Minute Intravenous Infusion' imgSrcDesktop={fullWidthImgDesktop} type='keytruda'>
                <H4>Dosage and administration for <keytitle><b>KEYTRUDA</b></keytitle> for patients with advanced renal cell carcinoma or advanced endometrial carcinoma</H4>
                <Ul>
                    <li>Continue treatment with KEYTRUDA until disease progression, unacceptable toxicity, or up to 24 months.</li>
                    <li>See full Prescribing Information for preparation and administration instructions and dosage modifications for adverse reactions.</li>
                </Ul>
            </TextImageFullWidth>
            <Dosing2Column imgSrc={dosingImg} desktopImgSrc={dosingImgDesktop} imgAlt='LENVIMA® (lenvatinib) Should Be Taken at the Same Time Each Day and Can Be Taken With or Without Food' title>
                <p>Dosage and administration for <lenlink>LENVIMA</lenlink> for patients with advanced renal cell carcinoma or advanced endometrial carcinoma<sup>a</sup></p>
                <span>When administered with KEYTRUDA.<br/><br/>LENVIMA is available in 4-mg and 10-mg capsules. Capsules are not  shown at actual size.</span>
                <Ul>
                    <li>If a dose is missed and cannot be taken within 12 hours, skip that dose and take the next dose at the usual time of administration.</li>
                    <li>Continue treatment with LENVIMA in combination with KEYTRUDA until disease progression or unacceptable toxicity.</li>
                    <li>After completing 2 years of combination therapy, LENVIMA may be administered as a single agent until disease progression or until unacceptable toxicity for advanced renal cell carcinoma.</li>
                    <li>The recommended dosage of LENVIMA for patients <b>with advanced renal cell carcinoma or advanced endometrial carcinoma and severe renal impairment</b> (creatinine clearance less than 30 mL/min calculated by Cockcroft-Gault equation using actual body weight) is <b>10 mg orally once daily</b>.</li>
                    <li>The recommended dosage of LENVIMA for patients <b>with advanced renal cell carcinoma or advanced endometrial carcinoma and severe hepatic impairment</b> (Child-Pugh C) is <b>10 mg orally once daily</b>.</li>
                </Ul>
                <div>
                    <p><b>Preparation of suspension</b></p>
                    <Ul>
                        <li>Place the required number of capsules, up to a maximum of 5, in a small container (approximately 20 mL capacity) or syringe (20 mL). Do not break or crush capsules.</li>
                        <li>Add 3 mL of liquid to the container or syringe. Wait 10 minutes for the capsule shell (outer surface) to disintegrate, then stir or shake the mixture for 3 minutes until capsules are fully disintegrated and administer the entire contents.</li>
                        <li>Next, add an additional 2 mL of liquid to the container or syringe using a second syringe or dropper, swirl or shake and administer. Repeat this step at least once and until there is no visible residue to ensure all of the medication is taken.</li>
                        <li>If 6 capsules are required for a dose, follow these instructions using 3 capsules at a time.</li>
                    </Ul>
                </div>
                <Body>
                    <p>
                    If LENVIMA suspension is not used at the time of preparation, LENVIMA suspension may be stored in a refrigerator at 36 °F to 46 °F (2 °C to 8 °C) for a maximum of 24 hours in a covered container. If not administered within 24 hours, the suspension should be discarded.
                    </p><br/>
                    <p><b>Note:</b> Compatibility has been confirmed for polypropylene syringes and for feeding tubes of at least 5 French diameter (polyvinyl chloride or polyurethane tube) and at least 6 French diameter (silicone tube).</p>
                </Body>
            </Dosing2Column>
            <StyledRC title='Find more support' links={RCLinks}/>
        </DefaultLayout>
    )
};

export default Dosing;
