import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { Body, DesktopOnly, H4Style, MobileOnly, Reference } from '../../util/typography';

const DosingContent = styled.div`
    max-width: 1032px;
    margin: 0 auto;
    padding: 15px 20px 30px 20px;
    display: flex;
    flex-direction: column;
    color: ${colors.charcoal};
    gap: 30px;
    ${respondTo.md`
        padding: 20px 30px 40px 30px;
    `}
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
`;

const Eyebrow = styled(Body)`
`;

const Title = styled.div`
    ${H4Style};
    lenlink {
        font-weight: 700;
        color: ${colors.mulberry};
    }
`;

const Columns = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    flex-direction: column;
    ${respondTo.md`
        flex-direction: row;
    `}
`;

const Column1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    img {
        width: 100%;
    }
    ${respondTo.md`
        img {
            width: 420px;
            height: 720px;
        }
    `}
`;

const Column2 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    align-self: stretch;
    div:nth-child(3) {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    li::marker, li::before {
        background-color: ${colors.mulberry};
    }
`;

const SupportingCopy = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
`;

const DosingRef = styled(Reference)`
    display: flex;
`;

const Dosing2Column = ({children, className, eyebrow, title, imgSrc, imgAlt='', desktopImgSrc}) => {
    return (
        <DosingContent className={className}>
            <Header>
                {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
                {title && <Title>{children[0]}</Title>}
            </Header>
            <Columns>
                <Column1>
                    <DesktopOnly><img src={desktopImgSrc} alt={imgAlt} /></DesktopOnly> 
                    <MobileOnly><img src={imgSrc} alt={imgAlt} /></MobileOnly> 
                    <DosingRef>
                        <p><sup>a</sup></p>
                        <p>{children[1]}</p>
                    </DosingRef>
                </Column1>
                <Column2>
                    {children[2]}
                    {children[3]}
                    {children[4]}
                </Column2>
            </Columns>
            <SupportingCopy>{children[5]}</SupportingCopy>
            {children[6] && children.slice(6)}
        </DosingContent>
)};
export default Dosing2Column;
