import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { Body, DesktopOnly, MobileOnly, Reference } from '../../util/typography';

const TextImageFullWidthContent = styled.div`
    padding: 0 20px 15px 20px;
    color: ${colors.charcoal};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    ${respondTo.md`
        max-width: 1032px;
        margin: 0 auto;
        padding: 0 30px 20px 30px;
    `}
    li::marker, li::before {
        background-color:${({type}) => type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : colors.charcoal};
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    keytitle {
        color: ${colors.brandGreen};
    }
    lentitle {
        color: ${colors.mulberry};
    }
`;

const ImageAndFootnotes = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    align-self: stretch;
    img {
        width: 100%;
    }
    ${respondTo.md`
        align-items: flex-start;
    `}
`;

const SupportingCopy = styled.div``;


const TextImageFullWidth = ({children, className, imgSrc, imgAlt = '', imgSrcDesktop, type, eyebrow, reference}) => {
    return (
        <TextImageFullWidthContent className={className} type={type}>
            <Header>
                {eyebrow && <Body>{eyebrow}</Body>}
                {children[0]}
            </Header>
            <ImageAndFootnotes>
                <MobileOnly><img src={imgSrc} alt={imgAlt} /></MobileOnly>
                <DesktopOnly><img src={imgSrcDesktop} alt={imgAlt} /></DesktopOnly>
                {reference && <Reference>
                    {children[1]}
                </Reference>}
            </ImageAndFootnotes>
            <SupportingCopy>
                {reference ? children[2] && children.slice(2) : children[1] && children.slice(1)}
            </SupportingCopy>
        </TextImageFullWidthContent>
)};
export default TextImageFullWidth;
